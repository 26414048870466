import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class BannerService {
	public banner: {
		isEnabled: boolean;
		title?: string;
		shortTitle?: string;
		icon?: string;
		isFitWidth?: boolean;
		isDismissible?: boolean;
		isSticky?: boolean;
		isFloating?: boolean;
		position?: string;
	} = {
		isEnabled: false,
		isFitWidth: false,
		isDismissible: true,
		isFloating: false,
		isSticky: false,
		position: 'end',
	};

	public get icon(): any {
		return this?._sanitizer?.bypassSecurityTrustHtml(this.banner.icon as string);
	}

	constructor(private _sanitizer: DomSanitizer) {}
}
