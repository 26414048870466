import { Injectable } from '@angular/core';
import isBefore from 'date-fns/isBefore';

import { environment } from './../../../environments/environment';

@Injectable({
	providedIn: 'root',
})
export class AppService {
	public get shouldShowOnlineBanking(): boolean {
		return isBefore(new Date(), new Date(environment.operationalDate));
	}

	public get onlineBankingURL(): string {
		// Return NetTeller URL until 9/1/2024 and then return Banno URL
		const now = new Date();
		if (isBefore(now, new Date(environment.operationalDate))) {
			return 'https://www.netteller.com/MultipliCreditUnion';
		}

		return 'https://bank.rrcu.org/login';
	}

	constructor() {}
}
