<xcu-banner *ngIf="bannerService.banner.isEnabled" [title]="bannerService.banner.title || ''"
  [shortTitle]="bannerService.banner.shortTitle || ''" [fit-width]="bannerService.banner.isFitWidth"
  [dismissible]="bannerService.banner.isDismissible" [floating]="bannerService.banner.isFloating"
  [sticky]="bannerService.banner.isSticky">
  <svg width="24" height="24" viewBox="0 0 24 24" [innerHTML]="bannerService.icon"></svg>
</xcu-banner>

<header xcu-header></header>

<main id="main">
  <router-outlet></router-outlet>

  <xcu-invo-widget />
</main>

<xcu-footer></xcu-footer>