import { NgModule } from '@angular/core';
import { AuthGuard, loggedIn } from '@angular/fire/auth-guard';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { Observable, UnaryFunction, pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { User } from './_objects/user';
import { PublicComponent } from './layouts/public/public.component';

// const redirectUnauthorizedToLogin: () => AuthPipe = () =>
//   redirectUnauthorizedTo(['login']);

export const redirectUnauthorizedToLogin = (route: ActivatedRouteSnapshot) => {
  const path = route.pathFromRoot
    .map((v) => v.url.map((segment) => segment.toString()).join('/'))
    .join('/');
  const params = route.queryParams;

  return pipe(
    loggedIn as UnaryFunction<Observable<User | null>, Observable<boolean | any[]>>,
    tap((isLoggedIn: boolean | any[]) => {
      if (!isLoggedIn) {
        sessionStorage.setItem('prevRoute', JSON.stringify({ path, params }));
      }
    }),
    map((loggedIn: boolean | any[]) => loggedIn || ['login'])
  );
};

const routes: Routes = [
  // Public pages
  {
    path: 'bank',
    component: PublicComponent,
    loadChildren: () => import('./pages/bank/bank.module').then((m) => m.BankModule),
  },
  {
    path: 'borrow',
    component: PublicComponent,
    loadChildren: () =>
      import('./pages/borrow/borrow.module').then((m) => m.BorrowModule),
  },
  {
    path: 'invest',
    component: PublicComponent,
    loadChildren: () =>
      import('./pages/invest/invest.module').then((m) => m.InvestModule),
  },
  {
    path: 'financial-wellness',
    component: PublicComponent,
    loadChildren: () =>
      import('./pages/financial-wellness/financial-wellness.module').then(
        (m) => m.FinancialWellnessModule
      ),
  },
  {
    path: 'join',
    component: PublicComponent,
    loadChildren: () => import('./pages/join/join.module').then((m) => m.JoinModule),
  },
  {
    path: 'privacy',
    component: PublicComponent,
    loadChildren: () => import('./pages/privacy/routes'),
  },
  {
    path: 'together',
    component: PublicComponent,
    loadChildren: () => import('./pages/together/routes'),
  },

  // {
  //   path: 'rates',
  //   component: PublicComponent,
  //   loadChildren: () =>
  //     import('./pages/rates/rates.module').then((m) => m.RatesModule),
  // },
  {
    path: 'disclosures',
    component: PublicComponent,
    loadChildren: () =>
      import('./pages/disclosures/disclosures.module').then((m) => m.DisclosuresModule),
  },
  {
    path: 'lobby-slides',
    loadChildren: () =>
      import('./pages/lobby-slides/lobby-slides.module').then((m) => m.LobbySlidesModule),
  },

  // Me profile page
  {
    path: 'me',
    component: PublicComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () => import('./pages/me/me.module').then((m) => m.MeModule),
  },

  // Auth
  {
    path: 'login',
    redirectTo: '/auth/login',
  },
  {
    path: 'auth',
    loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthModule),
  },

  // Intranet
  {
    path: 'intranet',
    loadChildren: () =>
      import('./pages/intranet/intranet.module').then((m) => m.IntranetModule),
  },

  // Manage
  {
    path: 'manage',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import('./pages/manage/manage.module').then((m) => m.ManageModule),
  },

  // Board Reports
  {
    path: 'board',
    component: PublicComponent,
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import('./pages/board-reports/board-reports.module').then(
        (m) => m.BoardReportsModule
      ),
  },

  // Style guide
  {
    path: 'style-guide',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    loadChildren: () =>
      import('./pages/style-guide/style-guide.module').then((m) => m.StyleGuideModule),
  },
  // Index page
  {
    path: '',
    component: PublicComponent,
    outlet: 'primary',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
  },
  // Error pages
  {
    path: '**',
    component: PublicComponent,
    loadChildren: () =>
      import('./pages/error/page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];

// Handle index.html request on Cloud Functions
if (typeof process !== 'undefined' && process.env?.['FUNCTION_NAME']) {
  routes.push({
    path: 'index.html',
    component: PublicComponent,
    outlet: 'primary',
    pathMatch: 'full',
  });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
