import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { Messaging } from '@angular/fire/messaging';
import { RemoteConfig, fetchAndActivate, getValue } from '@angular/fire/remote-config';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, Event, NavigationEnd, Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './../_services/auth/auth.service';
import { BannerService } from './../_services/banner/banner.service';
import { ThemeService } from './../_services/theme/theme.service';
import { UpdateService } from './../_services/update/update.service';

@Component({
	selector: 'xcu-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	public appName: string = environment.title;

	token$: Observable<any> = EMPTY;
	message$: Observable<any> = EMPTY;

	public constructor(
		@Optional() messaging: Messaging,
		@Optional() remoteConfig: RemoteConfig,
		public auth: AuthService,
		public updates: UpdateService,
		@Inject(DOCUMENT) private _document: Document,
		private _titleService: Title,
		private _metaService: Meta,
		private _themeService: ThemeService,
		private _bannerService: BannerService,
		private _router: Router,
		private _route: ActivatedRoute
	) {
		if (remoteConfig) {
			fetchAndActivate(remoteConfig).then(() => {
				if (remoteConfig) {
					this._bannerService.banner = {
						isEnabled: getValue(remoteConfig, 'banner_enabled').asBoolean(),
						title: getValue(remoteConfig, 'banner_title').asString(),
						shortTitle: getValue(remoteConfig, 'banner_short_title').asString(),
						icon: getValue(remoteConfig, 'banner_icon').asString(),
						isFitWidth: getValue(remoteConfig, 'banner_fit_width').asBoolean(),
						isDismissible: getValue(remoteConfig, 'banner_dismissible').asBoolean(),
						isSticky: getValue(remoteConfig, 'banner_sticky').asBoolean(),
						isFloating: getValue(remoteConfig, 'banner_floating').asBoolean(),
						position: getValue(remoteConfig, 'banner_position').asString(),
					};
				}
			});
		}

		// if (messaging) {
		// 	this.token$ = from(
		// 		navigator.serviceWorker
		// 			.register('firebase-messaging-sw.js', { type: 'module', scope: '__' })
		// 			.then((serviceWorkerRegistration) =>
		// 				getToken(messaging, {
		// 					serviceWorkerRegistration,
		// 					vapidKey: environment.vapidKey,
		// 				})
		// 			)
		// 	).pipe(
		// 		tap((token) => console.log('FCM', { token })),
		// 		share()
		// 	);
		// 	this.message$ = new Observable((sub) =>
		// 		onMessage(messaging, (it) => sub.next(it))
		// 	).pipe(tap((it) => console.log('FCM', it)));
		// 	this.token$.subscribe();
		// 	this.message$.subscribe();
		// }
	}

	public ngOnInit(): void {
		this._titleService.getTitle();
		this._router.events
			.pipe(
				filter((event: Event) => event instanceof NavigationEnd),
				map((event: Event) => {
					let child = this._route.firstChild;
					while ((child as ActivatedRoute).firstChild) {
						child = (child as ActivatedRoute).firstChild;
					}

					(child as ActivatedRoute).snapshot.data['originalTitle'] = (
						child as ActivatedRoute
					).snapshot.data['title'];

					(child as ActivatedRoute).snapshot.data['title'] = [
						(child as ActivatedRoute).snapshot.data['title'],
						environment.title,
					];

					if ((child as ActivatedRoute).snapshot.data['title']) {
						(child as ActivatedRoute).snapshot.data['title'] = (
							child as ActivatedRoute
						).snapshot.data['title']
							.filter((entry: string) => entry !== null && entry !== undefined)
							.join(' | ');
					}

					if (!(child as ActivatedRoute).snapshot.data['desc']) {
						(child as ActivatedRoute).snapshot.data['desc'] = environment.metaDescription;
					}

					return (child as ActivatedRoute).snapshot.data;
				})
			)
			.subscribe((data: Data) => {
				this._titleService.setTitle(data['title']);

				this._metaService.updateTag({
					name: 'description',
					content: data['desc'],
				});

				if (data['noindex']) {
					this._metaService.updateTag({
						name: 'robots',
						content: 'noindex',
					});
				}

				// Open Graph tags
				this._metaService.updateTag({
					name: 'og:url',
					content: this._document.location.href,
				});
				this._metaService.updateTag({
					name: 'og:title',
					content: data['originalTitle'] || data['title'],
				});
				this._metaService.updateTag({
					name: 'og:description',
					content: data['desc'],
				});
				this._metaService.updateTag({
					name: 'og:image',
					content: data['image'] || `/assets/logos/og.jpg`,
				});
				this._metaService.updateTag({
					name: 'og:site_name',
					content: environment.title,
				});
				this._metaService.updateTag({
					name: 'og:type',
					content: 'website',
				});
				this._metaService.updateTag({
					name: 'og:locale',
					content: 'en_US',
				});

				// Twitter tags
				this._metaService.updateTag({
					name: 'twitter:card',
					content: 'summary_large_image',
				});
				this._metaService.updateTag({
					name: 'twitter:creator',
					content: '@multiplicu',
				});
				this._metaService.updateTag({
					name: 'twitter:site',
					content: '@multiplicu',
				});
				this._metaService.updateTag({
					name: 'twitter:url',
					content: this._document.location.href,
				});
				this._metaService.updateTag({
					name: 'twitter:title',
					content: data['originalTitle'] || data['title'],
				});
				this._metaService.updateTag({
					name: 'twitter:description',
					content: data['desc'],
				});
				this._metaService.updateTag({
					name: 'twitter:image',
					content: data['image'] || `/assets/logos/og.jpg`,
				});
			});
	}

	public reload(): void {
		this._document.location.reload();
	}

	public request(): void {
		Notification.requestPermission();
	}
}
