import { Component } from '@angular/core';

import { BannerService } from './../../_services/banner/banner.service';

@Component({
	selector: 'xcu-page',
	templateUrl: './public.component.html',
	styleUrls: ['./public.component.scss'],
})
export class PublicComponent {
	public constructor(public bannerService: BannerService) {}
}
