export const environment = {
	name: 'Production',
	production: true,
	title: 'Multipli Credit Union',
	metaDescription:
		'At Multipli Credit Union we work to make your life easier through better interest rates, fewer fees, and more personal service than you could ever get at a bank.',
	firebaseConfig: {
		apiKey: 'AIzaSyA40zKjXdJy1OYgYcPM8z_kLrB6ubAe6Tk',
		authDomain: 'multiplicu.firebaseapp.com',
		databaseURL: 'https://multiplicu.firebaseio.com',
		projectId: 'multiplicu',
		storageBucket: 'multiplicu.appspot.com',
		messagingSenderId: '592047941044',
		appId: '1:592047941044:web:29dd466bfc0b9941b73ee1',
		measurementId: 'G-NVMPHPVPYF',
		cache: 'IndexedDbLocalCache',
	},
	firebaseFunctionsUrl: 'https://us-central1-multiplicu.cloudfunctions.net',
	vapidKey:
		'BBRviHw-BI_dQgirZIA3B3TPVeINXkPEYRQx9Z76lTRfnPaUhli0fqEZr1giTM1_ex36lxNPKr85h2mCcWYahIY',
	// App Check
	recaptcha3SiteKey: '6LfER-EaAAAAANbtkjcAlgbrczqtV0B6ekcVJ-LB',

	useEmulators: false,
	alogolia: {
		appId: 'FHPUVD87KF',
		searchKey: '5183ba207131c80945d284173fecb538',
		kbIndex: 'prod_KB_ARTICLES',
	},
	symXchangeApiRoute: 'https://api.multiplicu.com/api/',
	legacyApiRoute: 'https://api.multiplicu.com/api/nwo',
	thumbor: {
		key: 'xkDiHlZDucOp52HD',
		url: 'https://images.multiplicu.com',
	},
	memberAuthURL: 'https://api.multiplicu.com/api/public/auth',
	rrcuTenantID: 'e765379f-211d-485d-9014-3d2210ae6c80',
	operationalDate: '2024-09-01',
};
